import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import Moment from 'react-moment';
import logo from '../../assets/images/logo.svg';
import logo2020 from '../../assets/images/logo-2020.svg';
import isFeatureEnabled from '../../utils/isFeatureEnabled';

class NoBanks extends Component {
  render() {
    const logoSVG = isFeatureEnabled(
      this.props.userAttributes.features,
      "2020-q4-redesign-and-classification-tab"
    ) ? logo2020 : logo;

    return (
      <div className="row text-center" >
        <div id="nobanks" className="col-lg-9">
          <div className="card mt-4">
            <div className="row text-center">
              <img src={logoSVG} alt="PiggyBank" />
            </div>

            <div className="row text-center">
              <div className="col-lg-10 mx-auto mb-3 piggy-bank-description">
                <p>Welcome to TrueLayer's PFM application. Please think of Piggy Bank as your own
                  application UI when using this demo.</p>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-md-12">
                <button
                  type="button"
                  id="connect-bank"
                  className="btn"
                  onClick={this.props.openLink}
                >
                  Connect your bank
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="last-update" hidden>
          <p>
            <span>Last Updated</span>
            <Moment fromNow>{new Date()}</Moment>
          </p>
        </div>
      </div >
    );
  }
}

const propTypes = {
  openLink: PropTypes.func.isRequired,
  userAttributes: PropTypes.object.isRequired
}
NoBanks.propTypes = propTypes;

export default withRouter(NoBanks);
