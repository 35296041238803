import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from 'bugsnag-js';

import { deleteBanks } from '../../actions/Banks';
import { autoAlert } from '../../actions/Alert';
import { logoutUser } from '../../actions/User';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import SidebarBankItem from '../SidebarBankItem/SidebarBankItem';

import authFetch from '../../utils/Fetch';
import { getUsersApiUri } from "../../utils/Settings";

import history from '../../history';

import {
  activeOauthProviders,
  disableOauthProviders,
  activeOpenBankingProviders,
  disableOpenBankingProviders,
  disableMockBank,
  enableMockBank
} from "../../actions/General";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.removeAllBanks = this.removeAllBanks.bind(this);

    this.setOAuthProviders = this.setOAuthProviders.bind(this);
    this.setOBProviders = this.setOBProviders.bind(this);
    this.setMockBank = this.setMockBank.bind(this);
  }
  logout(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(logoutUser());
  }
  removeAllBanks() {
    const { dispatch } = this.props;

    authFetch.delete(`${getUsersApiUri()}/banks/all`)
      .then((response) => {
        dispatch(autoAlert('You have disconnected your bank accounts.', 'success', 'Success!'));
        dispatch(deleteBanks());
        history.push('/');
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }
        const metadata = { "special info": { response: err.response } };
        Bugsnag.notifyException(err, "Error disconneting bank account", metadata, "error");
        dispatch(autoAlert('Something went wrong. You may want to try again ?', 'warning', 'Ooops!'));
      })
  }

  setOAuthProviders() {
    const { dispatch } = this.props;
    if (this.props.oauth_providers) {
      dispatch(disableOauthProviders())
    } else {
      dispatch(activeOauthProviders())
    }
  }
  setOBProviders() {
    const { dispatch } = this.props;
    if (this.props.openbanking_active) {
      dispatch(disableOpenBankingProviders())
    } else {
      dispatch(activeOpenBankingProviders())
    }
  }
  setMockBank() {
    const { dispatch } = this.props;
    if (this.props.enable_mock_bank) {
      dispatch(disableMockBank())
    } else {
      dispatch(enableMockBank())
    }
  }
  render() {
    return (
      <div id="sidebar" className="col-lg-3">
        <div className="linked-accounts">
          <p>
            You have <span>{this.props.bankItems.length}</span> {this.props.bankItems.length === 1 ? 'account' : 'accounts'} <br /> linked to Piggy Bank.
              </p>
          <h6 className="list-group-title text-center">Add bank account</h6>
          <div className="text-center">
            <button type="button" id="connect-bank" className="btn btn-piggy-small text-center" onClick={this.props.openLink}>Connect your bank</button>
          </div>
        </div>
        <div className="list-group">

          {this.props.bankItems.map((bank, i) => {
            return <SidebarBankItem bank={bank} key={bank.id} />;
          })}
          <div className="list-group-item logout">
            <button onClick={() => { this.removeAllBanks() }}>Remove All Accounts</button>
          </div>
        </div>
      </div>
    );
  }
}
const propTypes = {
  bankItems: PropTypes.array.isRequired,
  openLink: PropTypes.func.isRequired
}
Sidebar.propTypes = propTypes;

function mapStateToProps(state) {
  const { banks, general } = state;
  const { items } = banks;
  const { oauth_providers, openbanking_active, enable_mock_bank } = general;

  return {
    bankItems: items,
    oauth_providers,
    openbanking_active,
    enable_mock_bank,
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar));
