import React from 'react';

const Footer = () => {
  
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <footer className="py-5">
        <div className="container">
            <p className="m-0 text-center">© TrueLayer Limited {getCurrentYear()}. This application is for demonstrative purposes only. We do not store your data at any time.</p>
        </div>
    </footer>
  )
}

export default Footer;
