import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from 'react-redux';

import "./AccountTabNav.css";

class AccountTabNav extends Component {
  render() {
    const bankId = this.props.bankId;
    const accountId = this.props.accountId;
    const accountType = this.props.accountType;
    const pathOverview = `/banks/${bankId}/${accountType}s/${accountId}`;
    const pathClassification = pathOverview + "/classification";

    return (
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink to={pathOverview} className="nav-link" exact>Overview</NavLink>
        </li>

        <li className="nav-item">
          <NavLink to={pathClassification} className="nav-link" exact>Classification</NavLink>
        </li>
      </ul>
    );
  }
}

const propTypes = {
  bankId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired
}
AccountTabNav.propTypes = propTypes;

export default withRouter(connect()(AccountTabNav));
