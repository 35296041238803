import Auth0Lock from "auth0-lock";
import history from "../history";
import { getAuth0Audience, getAuth0ClientId, getAuth0Domain, getAuth0RedirectUri } from "../utils/Settings";

const createLock = () => {
  return new Auth0Lock(
    getAuth0ClientId(),
    getAuth0Domain(),
    {
      autoclose: true,
      configurationBaseUrl: "https://cdn.eu.auth0.com",
      auth: {
        redirectUrl: getAuth0RedirectUri(),
        responseType: "token id_token",
        audience: getAuth0Audience(),
        params: {
          scope: "openid"
        }
      },
      theme: {
        logo: "https://truelayer-public-assets.s3-eu-west-1.amazonaws.com/logo/mark/tl-yellow.svg",
        primaryColor: "#058ed8"
      },
      languageDictionary: {
        title: ""
      }
    }
  );
}

class Auth {
  constructor() {
    // binds functions to keep this context
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);

    this.lock = createLock();

    this.handleAuthentication();
  }

  login() {
    // Call the show method to display the widget.
    this.lock.show();
  }

  signup() {
    // Call the show method to display the widget.
    this.lock.show({ initialScreen: "signUp" });
  }

  handleAuthentication() {
    // Add a callback for Lock's `authenticated` event
    this.lock.on("authenticated", this.setSession.bind(this));
    // Add a callback for Lock's `authorization_error` event
    // TODO: Google login shouldn't give nonce error...
    this.lock.on("authorization_error", err => {
      //console.log(err);
      //alert(`Error: ${err.error}. Check the console for further details.`);
      //window.location.href = '/';
    });
  }

  setSession(authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at
      let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      localStorage.setItem("access_token", authResult.accessToken);
      localStorage.setItem("id_token", authResult.idToken);
      localStorage.setItem("expires_at", expiresAt);
      // navigate to the home route
      window.location.href = "/callback/auth";
    }
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    // navigate to the home route
    history.replace("/");
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      //throw new Error('No access token found');
      this.logout();
    }
    return accessToken;
  }

  getIdToken(cb) {
    const idToken = localStorage.getItem("id_token");
    if (!idToken) {
      //throw new Error('No access token found');
      this.logout();
    }
    return idToken;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }
}

const auth = new Auth();
export default auth;