const beautyDate = (date) => {
  const newDate = new Date(date);
  var dd = newDate.getDate();
  var mm = newDate.getMonth();
  var yyyy = newDate.getFullYear();
  const month_name = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const newDateFormat = `${dd} ${month_name[mm]} ${yyyy}`;

  return newDateFormat;
}

export default beautyDate;
