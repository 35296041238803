import NonceGenerator from "a-nonce-generator";

import { TRUELAYER_DEFAULT_SCOPE } from "../constants/Truelayer";
import { getTrueLayerAuthUri, getTrueLayerCallback, getTrueLayerClientId } from "./Settings";

// generate a truelayer auth link with a nonce
const generateTruelayerLink = (
  redirectURI = getTrueLayerCallback(),
  scope = TRUELAYER_DEFAULT_SCOPE,
) => {
  const ng = new NonceGenerator();
  const nonce = ng.generate();

  const concatScope = scope.join(" ");
  let authUrl =
    `${getTrueLayerAuthUri()}/?` +
    `response_type=code&` +
    `client_id=${getTrueLayerClientId()}&` +
    `redirect_uri=${redirectURI}&` +
    `scope=${concatScope}&` +
    `nonce=${nonce}&` +
    `providers=all-all-all`;
  return encodeURI(authUrl);
};

export default generateTruelayerLink;
