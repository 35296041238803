import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {showContentLoading, hideContentLoading } from '../../actions/General';

import { autoAlert } from '../../actions/Alert';
import { logoutUser } from '../../actions/User';

import authFetch from '../../utils/Fetch';

import ContentPageLoader from '../../components/ContentPageLoader/ContentPageLoader';
import { getUsersApiUri } from "../../utils/Settings";

import Moment from 'react-moment';

class AccountInfo extends Component {
  constructor(props){
    super(props);

    this.state = {
      identity: {},
      fetched: false,
      identityError: false,
      infoFetchedAt: null
    }

    this.fetchCustomerData = this.fetchCustomerData.bind(this);
    this.refetchCustomerData = this.refetchCustomerData.bind(this);
  }
  componentDidMount() {
    const bank_id = this.props.match.params.bank_id;
    this.fetchCustomerData(bank_id);
  }
  componentWillUpdate(nextProps, nextState){
    if (this.props.match.params.bank_id !== nextProps.match.params.bank_id) {
      const bank_id = nextProps.match.params.bank_id;
      this.setState({identity: {}, fetched: false, identityError: false, infoFetchedAt: null});
      this.fetchCustomerData(bank_id);
    }
  }
  fetchCustomerData(bank_id){
    const { dispatch } = this.props;

    dispatch(showContentLoading());

    this.setState({identityError: false});

    authFetch.get(`${getUsersApiUri()}/banks/${bank_id}/info`)
      .then((response) => {
        this.setState({identity: response.data, fetched: true, identityError: false, infoFetchedAt: new Date()});
        dispatch(hideContentLoading());
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }
        dispatch(hideContentLoading());
        this.setState({identityError: true});
        console.log(err);
      })
  }
  refetchCustomerData(event){
    event.preventDefault();

    const bank_id = this.props.match.params.bank_id;
    this.fetchCustomerData(bank_id);
  }
  render() {
    return (
      <div className="col-lg-9">
          <div className={"card mt-4 " + (this.props.contentLoading ? 'is-loading' : '')}>
              <div className="row">
                  <div className="col col-lg-6">
                      <h5>Personal information</h5>
                  </div>
                  {!this.state.identityError && this.state.fetched && (
                    <div className="col col-lg-6 text-right last-update">
                      {this.state.infoFetchedAt &&
                        <p>Updated <Moment fromNow>{this.state.infoFetchedAt}</Moment></p>
                      }
                    </div>
                  )}
              </div>
              {(this.props.contentLoading) ? (
                <div id="informations" className="row double-col">
                  <ContentPageLoader />
                </div>
              ) : (
                <div>
                  {!this.state.identityError ? (
                    <div>
                      {Object.keys(this.state.identity).length > 0 ? (
                        <div id="informations" className="row double-col">
                          <div className="col col-lg-6 col-md-6">
                              <div className="row">
                                  <div className="col col-lg-5">
                                      Name
                                  </div>
                                  <div className="col col-lg-7">
                                      {this.state.identity.full_name &&
                                        <div>{this.state.identity.full_name}</div>
                                      }
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      Phone
                                  </div>
                                  <div className="col col-lg-7">
                                    {(this.state.identity.phones && this.state.identity.phones.length > 0) &&
                                      <div>{this.state.identity.phones[0]}</div>
                                    }
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      e-mail
                                  </div>
                                  <div className="col col-lg-7">
                                    {(this.state.identity.emails && this.state.identity.emails.length > 0) &&
                                      <div>{this.state.identity.emails[0]}</div>
                                    }
                                  </div>
                              </div>
                          </div>
                          <div className="col col-lg-6 col-md-6">
                              <div className="row">
                                  <div className="col col-lg-5">
                                      Address
                                  </div>
                                  <div className="col col-lg-7">
                                    {this.state.identity.addresses &&
                                      this.state.identity.addresses.length &&
                                      <div>{this.state.identity.addresses[0].address}</div>
                                    }
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      State
                                  </div>
                                  <div className="col col-lg-7">
                                      -
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      Post-code
                                  </div>
                                  <div className="col col-lg-7">
                                    {this.state.identity.addresses &&
                                      this.state.identity.addresses.length &&
                                      <div>{this.state.identity.addresses[0].zip}</div>
                                    }
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      City
                                  </div>
                                  <div className="col col-lg-7">
                                    {this.state.identity.addresses &&
                                      this.state.identity.addresses.length &&
                                      <div>{this.state.identity.addresses[0].city}</div>
                                    }
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col col-lg-5">
                                      Country
                                  </div>
                                  <div className="col col-lg-7">
                                    {this.state.identity.addresses &&
                                      this.state.identity.addresses.length &&
                                      <div>{this.state.identity.addresses[0].country}</div>
                                    }
                                  </div>
                              </div>
                          </div>
                        </div>
                      ) : (
                        <div id="informations" className="row double-col">
                          {this.state.fetched && (
                            <span>No personal info found...</span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div id="informations" className="row">
                      <div className="error">Something went wrong. <button onClick={this.refetchCustomerData}>You may want to try again?</button></div>
                    </div>
                  )}
                </div>

              )}
          </div>

      </div>
    );
  }
}
const propTypes = {
  dispatch: PropTypes.func.isRequired
}
AccountInfo.propTypes = propTypes;

function mapStateToProps(state){
  const { user, general, banks } = state;

  const { contentLoading } = general;
  const { isSignedIn, attributes } = user;
  const { items } = banks;

  return {
    contentLoading,
    isSignedIn,
    userAttributes: attributes,
    bankItems: items
  }
}

export default withRouter(connect(mapStateToProps)(AccountInfo));
