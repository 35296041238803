import * as types from "../constants/ActionTypes";

function setShowPageLoading() {
  return {type: types.SHOW_PAGE_LOADING};
}

function setHidePageLoading() {
  return {type: types.HIDE_PAGE_LOADING};
}

function setShowSubmitLoading() {
  return {type: types.SHOW_SUBMIT_LOADING};
}

function setHideSubmitLoading() {
  return {type: types.HIDE_SUBMIT_LOADING};
}

function setShowAccountsLoading() {
  return {type: types.SHOW_ACCOUNTS_LOADING};
}

function setHideAccountsLoading() {
  return {type: types.HIDE_ACCOUNTS_LOADING};
}

function setShowContentLoading() {
  return {type: types.SHOW_CONTENT_LOADING};
}

function setHideContentLoading() {
  return {type: types.HIDE_CONTENT_LOADING};
}

function saveCurrentAccount(account) {
  return {type: types.SET_CURRENT_ACCOUNT, account: account};
}

function saveFirstAccountLoaded(bank_id, account_id) {
  return {type: types.SET_FIRST_ACCOUNT_LOADED, bank_id: bank_id, account_id: account_id};
}

function saveClearFirstAccountLoaded() {
  return {type: types.CLEAR_FIRST_ACCOUNT_LOADED};
}

export function showPageLoading() {
  return dispatch => {
    dispatch(setShowPageLoading());
  };
}

export function hidePageLoading() {
  return dispatch => {
    dispatch(setHidePageLoading());
  };
}

export function showSubmitLoading() {
  return dispatch => {
    dispatch(setShowSubmitLoading());
  };
}

export function hideSubmitLoading() {
  return dispatch => {
    dispatch(setHideSubmitLoading());
  };
}

export function showAccountsLoading() {
  return dispatch => {
    dispatch(setShowAccountsLoading());
  };
}

export function hideAccountsLoading() {
  return dispatch => {
    dispatch(setHideAccountsLoading());
  };
}

export function showContentLoading() {
  return dispatch => {
    dispatch(setShowContentLoading());
  };
}

export function hideContentLoading() {
  return dispatch => {
    dispatch(setHideContentLoading());
  };
}

export function setCurrentAccount(account) {
  return dispatch => {
    dispatch(saveCurrentAccount(account));
  };
}

export function setFirstAccountLoaded(bank_id, account_id) {
  return dispatch => {
    dispatch(saveFirstAccountLoaded(bank_id, account_id));
  };
}

export function clearFirstAccountLoaded() {
  return dispatch => {
    return dispatch(saveClearFirstAccountLoaded());
  };
}

export function activeOauthProviders() {
  return {type: types.ACTIVE_OAUTH_PROVIDERS};
}

export function disableOauthProviders() {
  return {type: types.DISABLE_OAUTH_PROVIDERS};
}

export function activeOpenBankingProviders() {
  return {type: types.ACTIVE_OPENBANKING_PROVIDERS};
}

export function disableOpenBankingProviders() {
  return {type: types.DISABLE_OPENBANKING_PROVIDERS};
}

export function enableMockBank() {
  return {type: types.ENABLE_MOCK_BANK}
}

export function disableMockBank() {
  return {type: types.DISABLE_MOCK_BANK}
}
