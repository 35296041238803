function getConfiguration() {
  if (process.env.NODE_ENV === 'production') {
    return window.env_config;
  }

  return process.env;
};

export const getBugsnagClientId = () => {
  const {REACT_APP_BUGSNAG_CLIENT_ID} = getConfiguration();
  return REACT_APP_BUGSNAG_CLIENT_ID;
};

export const getUsersApiUri = () => {
  const {REACT_APP_USERS_API_BASEURL} = getConfiguration();
  return REACT_APP_USERS_API_BASEURL;
};

export const getTrueLayerCallback = () => {
  const {REACT_APP_TRUELAYER_CALLBACK} = getConfiguration();
  return REACT_APP_TRUELAYER_CALLBACK;
};

export const getTrueLayerScopes = () => {
  const {REACT_APP_TRUELAYER_DEFAULT_SCOPE} = getConfiguration();
  return REACT_APP_TRUELAYER_DEFAULT_SCOPE;
};

export const getTrueLayerAuthUri = () => {
  const {REACT_APP_TRUELAYER_AUTH_URL} = getConfiguration();
  return REACT_APP_TRUELAYER_AUTH_URL;
};

export const getTrueLayerClientId = () => {
  const {REACT_APP_TRUELAYER_CLIENT_ID} = getConfiguration();
  return REACT_APP_TRUELAYER_CLIENT_ID;
};

export const getAuth0ClientId = () => {
  const {REACT_APP_AUTH0_CLIENT_ID} = getConfiguration();
  return REACT_APP_AUTH0_CLIENT_ID;
};

export const getAuth0Domain = () => {
  const {REACT_APP_AUTH0_DOMAIN} = getConfiguration();
  return REACT_APP_AUTH0_DOMAIN;
};

export const getAuth0RedirectUri = () => {
  const {REACT_APP_AUTH0_REDIRECT_URI} = getConfiguration();
  return REACT_APP_AUTH0_REDIRECT_URI;
};

export const getAuth0Audience = () => {
  const {REACT_APP_AUTH0_AUDIENCE} = getConfiguration();
  return REACT_APP_AUTH0_AUDIENCE;
};

export const isAlphaVersion = () => {
  const {REACT_APP_IS_ALPHA} = getConfiguration();
  return REACT_APP_IS_ALPHA === "true";
}
