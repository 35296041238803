import * as types from '../constants/ActionTypes';

const initialState = {
  items: [],
  banksFetched: false
};

const banks = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_BANKS:
      return {
        ...state,
        items: action.items,
        banksFetched: true
      };

    case types.DELETE_BANKS:
      return {
        ...state,
        items: [],
        banksFetched: false
      };

    case types.DELETE_BANK:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.bank_id)
      };

    default:
      return state;
  }
}

export default banks;
