import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import Bugsnag from 'bugsnag-js';
import { unregister } from "./registerServiceWorker"

import AppRouter from './AppRouter';
// import general app styles
import 'bootstrap/dist/css/bootstrap.css';

import './assets/stylesheets/main.css';
import './assets/stylesheets/truelayer.css';
import './assets/stylesheets/redesign-2020.css';

// import store, routes and history
import configureStore from './store/configureStore';
import history from './history';
import { getBugsnagClientId } from "./utils/Settings";

const initialState = window.INITIAL_STATE || {};
const store = configureStore(history, initialState);

Bugsnag.apiKey = getBugsnagClientId();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <AppRouter />
    </Router>
  </Provider>
, document.getElementById('root'));

unregister();
