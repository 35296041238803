import { combineReducers } from 'redux'
import alert from './alert'
import general from './general'
import user from './user'
import banks from './banks'

const rootReducer = combineReducers({
  alert,
  general,
  user,
  banks
})

export default rootReducer;
