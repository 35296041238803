const invalid_card_networks = [
  "NOT_SUPPORTED_BY_GEO",
  "NOT_SUPPORTED_BY_PROVIDER"
];

const displayableCardNetwork = (cardNetwork) => {
  return !!cardNetwork && !invalid_card_networks.includes(cardNetwork)
}

export default displayableCardNetwork;
