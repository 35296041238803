import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import HeaderNavbar from "../../components/HeaderNavbar/HeaderNavbar";
import SubHeader from "../../components/SubHeader/SubHeader";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import ContentPageLoader from "../../components/ContentPageLoader/ContentPageLoader";

import Home from "../Home/Home";
import Account from "../Account/Account";
import AccountClassification from "../AccountClassification/AccountClassification";
import AccountInfo from "../AccountInfo/AccountInfo";
import NoBanks from "../NoBanks/NoBanks";

import { getBanks } from "../../actions/Banks";

import generateTruelayerLink from "../../utils/Truelayer";
import PopupCenter from "../../utils/PopupCenter";
import { getTrueLayerCallback, getTrueLayerScopes } from "../../utils/Settings";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

class Authentified extends Component {
  constructor(props) {
    super(props);

    this.openLink = this.openLink.bind(this);
  }

  componentWillMount() {
    if (this.props.bankItems.length === 0) {
      const { dispatch } = this.props;
      dispatch(getBanks());
    }
  }

  openLink(event) {
    event.preventDefault();
    const link = generateTruelayerLink(
      getTrueLayerCallback(),
      getTrueLayerScopes(),
      this.props.enable_mock_bank,
      this.props.oauth_providers,
      this.props.openbanking_active
    );

    console.log(link);
    PopupCenter(link, "TrueLayer - Connect Your Bank Account", "814", "778");
  }

  render() {
    const featureClass = isFeatureEnabled(
      this.props.userAttributes.features,
      "2020-q4-redesign-and-classification-tab"
    ) ? "redesign-2020" : null;

    return (
      <div className={"Wrapper " + featureClass}>
        <HeaderNavbar userAttributes={this.props.userAttributes} />

        <section id="navbar-margin">
          <SubHeader
            userAttributes={this.props.userAttributes}
            bankItems={this.props.bankItems}
          />

          <div className="container">
            {this.props.banksLoading ? (
              <div className="row">
                <div>
                  <ContentPageLoader />
                </div>
              </div>
            ) : (
                <div>
                  {this.props.bankItems.length > 0 ? (
                    <div className="row">
                      <Sidebar
                        auth={this.props.auth}
                        bankItems={this.props.bankItems}
                        openLink={this.openLink}
                      />

                      <Switch>
                        <Route exact path="/" render={() => <Home />} />

                        <Route
                          exact
                          path="/banks/:bank_id/accounts/:account_id"
                          render={() => <Account accountType="account" />}
                        />

                        <Route
                          exact
                          path="/banks/:bank_id/accounts/:account_id/classification"
                          render={() => <AccountClassification accountType="account" />}
                        />

                        <Route
                          exact
                          path="/banks/:bank_id/cards/:account_id"
                          render={() => <Account accountType="card" />}
                        />

                        <Route
                          exact
                          path="/banks/:bank_id/cards/:account_id/classification"
                          render={() => <AccountClassification accountType="card" />}
                        />

                        <Route
                          exact
                          path="/banks/:bank_id/info"
                          component={AccountInfo}
                        />
                      </Switch>
                    </div>
                  ) : (
                    <NoBanks
                      openLink={this.openLink}
                      userAttributes={this.props.userAttributes}
                    />
                    )}
                </div>
              )}
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired
};
Authentified.propTypes = propTypes;

function mapStateToProps(state) {
  const { general, user, banks } = state;

  const {
    pageLoading,
    banksLoading,
    oauth_providers,
    openbanking_active,
    enable_mock_bank,
  } = general;

  const { isSignedIn, attributes } = user;
  const { items } = banks;

  return {
    pageLoading,
    banksLoading,
    isSignedIn,
    userAttributes: attributes,
    bankItems: items,
    oauth_providers,
    openbanking_active,
    enable_mock_bank,
  };
}

export default withRouter(connect(mapStateToProps)(Authentified));
