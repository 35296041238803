import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';

import ContentPageLoader from '../../components/ContentPageLoader/ContentPageLoader';
import TransactionCategoryIcon from '../../components/TransactionCategoryIcon/TransactionCategoryIcon';
import AccountTabNav from "../../components/AccountTabNav/AccountTabNav";
import BarChart from "../../components/BarChart/BarChart";

import { autoAlert } from '../../actions/Alert';
import { logoutUser } from '../../actions/User';

import authFetch from '../../utils/Fetch';
import beautyDate from '../../utils/BeautyDate';
import formattedCurrencyAmount from "../../utils/FormattedCurrencyAmount"
import { isAlphaVersion, getUsersApiUri } from "../../utils/Settings";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

class Account extends Component {
  constructor(props){
    super(props);

    this.state = {
      balance: {},
      transactions: [],
      overviewByMonth: [],
      balanceFetched: false,
      showBalanceLoader: false,
      balanceError: false,
      balanceFetchedAt: null,
      transactionsFetched: false,
      showTransactionsLoader: false,
      transactionsError: false,
      showMoreTransactionsLoader: false,
      transactionsToDate: "",
      transactionsFetchedAt: null,
      pendingTransactionsEndpointSupported: true,
      pendingTransactions: [],
      showPendingTransactionsLoader: false,
      pendingTransactionsFetched: false,
      pendingTransactionsError: false,
      pendingTransactionsFetchedAt: null,
      showAccessTokenLoader:false,
      accessToken : ""
    };

    this.fetchAccessToken = this.fetchAccessToken.bind(this);
    this.postDebugId = this.postDebugId.bind(this);
    this.fetchAccountBalance = this.fetchAccountBalance.bind(this);
    this.fetchAccountTransactions = this.fetchAccountTransactions.bind(this);
    this.fetchAccountPendingTransactions = this.fetchAccountPendingTransactions.bind(this);
    this.loadMoreTransactions = this.loadMoreTransactions.bind(this);
    this.refetchAccountBalance = this.refetchAccountBalance.bind(this);
    this.refetchAccountTransactions = this.refetchAccountTransactions.bind(this);
    this.refetchAccountPendingTransactions = this.refetchAccountPendingTransactions.bind(this);
    this.getMonthShorthand = this.getMonthShorthand.bind(this);
    this.getPositiveAmount = this.getPositiveAmount.bind(this);
    this.getRawMonthItems = this.getRawMonthItems.bind(this);
    this.buildOverviewPayload = this.buildOverviewPayload.bind(this);
  }

  componentDidMount() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountBalance(bank_id, account_id, accountType);
    this.fetchAccountPendingTransactions(bank_id, account_id, accountType);
    this.fetchAccountTransactions(bank_id, account_id, accountType);
    this.fetchAccessToken(bank_id);
  }

  componentWillUpdate(nextProps, nextState){
    if (this.props.match.params.account_id !== nextProps.match.params.account_id) {
      const bank_id = nextProps.match.params.bank_id;
      const account_id = nextProps.match.params.account_id;
      const accountType = nextProps.accountType;

      this.setState({
        transactions: [],
        overviewByMonth: [],
        pendingTransactions: [],
        balance: {},
        balanceFetchedAt: null,
        transactionsFetchedAt: null,
        pendingTransactionsFetchedAt: null,
        accessToken:""
      });

      this.fetchAccountBalance(bank_id, account_id, accountType);
      this.fetchAccountPendingTransactions(bank_id, account_id, accountType);
      this.fetchAccountTransactions(bank_id, account_id, accountType);
      this.fetchAccessToken(bank_id);
    }
  }

  loadMoreTransactions(event){
    event.preventDefault();

    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;

    this.fetchAccountTransactions(bank_id, account_id, accountType, true);
  }

  fetchAccessToken(bank_id) {
    // truelayer access token
    this.setState({ showAccessTokenLoader: true });
    authFetch.get(`${getUsersApiUri()}/banks/${bank_id}/access_token`)
      .then((res)=>{
        const token = res.data.access_token;
        this.setState({ showAccessTokenLoader: false });
        this.setState({ accessToken: token });
      })
      .catch((err)=>{
        console.log(err);
        this.setState({ accessToken: "failed to fetch" });
      });
  }

  postDebugId(){
    const { dispatch } = this.props;

    // submitDebugToken(accesstoken)
    authFetch.post(`${getUsersApiUri()}/banks/debug`,{access_token:this.state.accessToken})
      .then((response) => {
        if (response.data.err != null) {
          dispatch(autoAlert('That did not go so well. The debug id wasn\'t generated.', 'warning', 'Ooops!'));
        } else {
          if (response.data.debug_id) {
            dispatch(autoAlert(`Your Debug ID has been posted! \n debug ID : ${response.data.debug_id}`, 'success', 'Success!'));
          } else {
            dispatch(autoAlert(`Your Debug ID has been posted!`, 'success', 'Success!'));
          }
        }
      })

      .catch((err) => {
        console.log(err);
        dispatch(autoAlert('That did not go so well. The debug id wasn\'t generated.', 'warning', 'Ooops!'));
      })
  }

  fetchAccountBalance(bank_id, account_id, accountType){
    const { dispatch } = this.props;

    this.setState({ showBalanceLoader: true, balanceError: false })

    let url = "";
    if (accountType === "account") {
      url = `${getUsersApiUri()}/banks/${bank_id}/accounts/${account_id}/balance`;
    } else {
      url = `${getUsersApiUri()}/banks/${bank_id}/cards/${account_id}/balance`;
    }
    authFetch.get(url)
      .then((response) => {
        this.setState({
          balance: response.data,
          showBalanceLoader: false,
          balanceFetched: true,
          balanceError: false,
          balanceFetchedAt: new Date()
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }
        this.setState({
          showBalanceLoader: false,
          balanceFetched: false,
          balanceError: true
        });
        console.log(err);
      })
  }

  fetchAccountTransactions(bank_id, account_id, accountType, loadMore = false) {
    const { dispatch } = this.props;

    if (loadMore) {
      this.setState({ showMoreTransactionsLoader: true })
    } else {
      this.setState({ showTransactionsLoader: true, transactionsError: false })
    }

    let url = "";
    if (accountType === "account") {
      url = `${getUsersApiUri()}/banks/${bank_id}/accounts/${account_id}/transactions`;
    } else {
      url = `${getUsersApiUri()}/banks/${bank_id}/cards/${account_id}/transactions`;
    }

    let queryParams;
    if (this.state.transactionsToDate && loadMore) {
      queryParams = {params: {toDate: this.state.transactionsToDate}}
    } else {
      queryParams = {}
    }

    authFetch.get(url, queryParams)
      .then((response) => {
        if (loadMore) {
          this.setState({
            transactions: this.state.transactions.concat(response.data.transactions),
            transactionsToDate: response.data.toDate,
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: true,
            transactionsFetchedAt: new Date(),
            overviewByMonth: response.data.overview_by_month
          });
        } else {
          this.setState({
            transactions: response.data.transactions,
            transactionsToDate: response.data.toDate,
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: true,
            transactionsFetchedAt: new Date(),
            overviewByMonth: response.data.overview_by_month
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }
        this.setState({
          showTransactionsLoader: false,
          showMoreTransactionsLoader: false,
          transactionsFetched: false
        });
        console.log(err);
        if (loadMore) {
          this.setState({
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: false
          });
        } else {
          this.setState({
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: false,
            transactionsError: true
          });
        }
      })
  }

  fetchAccountPendingTransactions(bank_id, account_id, accountType) {
    const { dispatch } = this.props;

    this.setState({ showPendingTransactionsLoader: true, pendingTransactionsError: false });

    let url = "";
    if (accountType === "account") {
      url = `${getUsersApiUri()}/banks/${bank_id}/accounts/${account_id}/transactions/pending`;
    } else {
      url = `${getUsersApiUri()}/banks/${bank_id}/cards/${account_id}/transactions/pending`;
    }

    authFetch.get(url)
      .then((response) => {
        this.setState({
          pendingTransactions: response.data.transactions,
          showPendingTransactionsLoader: false,
          pendingTransactionsFetched: true,
          pendingTransactionsFetchedAt: new Date()
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }

        // Handle the endpoint_not_supported case in a nice way
        if (err.response.status === 501) {
          this.setState({
            pendingTransactions: [],
            showPendingTransactionsLoader: false,
            pendingTransactionsFetched: true,
            pendingTransactionsFetchedAt: new Date()
          });
          return;
        }

        this.setState({
          showPendingTransactionsLoader: false,
          pendingTransactionsFetched: false,
          pendingTransactionsError: true
        });
      })
  }

  refetchAccountBalance() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountBalance(bank_id, account_id, accountType);
  }

  refetchAccountTransactions() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountTransactions(bank_id, account_id, accountType);
  }

  refetchAccountPendingTransactions() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountPendingTransactions(bank_id, account_id, accountType);
  }

  // Given "2020-12", return "Dec"
  getMonthShorthand(monthString) {
    let monthDate = new Date(monthString);
    return new Intl.DateTimeFormat("default", { month: "short"  }).format(monthDate);
  }

  getPositiveAmount(amount) {
    let positiveAmount = amount < 0 ? Math.abs(amount) : amount;
    return positiveAmount.toFixed(2);
  }

  // Sorts months are in ascending order
  getRawMonthItems() {
    return this.state.overviewByMonth
      .sort((a,b) => (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0));
  }

  buildOverviewPayload() {
    let monthItems = this.getRawMonthItems();

    return [
      {
        label: "Incoming",
        data: monthItems.map((i) => { return this.getPositiveAmount(i.total_incoming) }),
        backgroundColor: "rgba(5, 15, 102, 1)"
      },
      {
        label: "Outgoing",
        data: monthItems.map((i) => { return this.getPositiveAmount(i.total_outgoing) }),
        backgroundColor: "rgba(84, 99, 226, 1)"
      }
    ]
  }

  render() {
    let n_balances = 0;
    if(this.state.balance.hasOwnProperty('current')) n_balances++;
    if(this.state.balance.hasOwnProperty('available')) n_balances++;

    const balance_col_size = n_balances === 1 ? 12 : 6;

    const isNewDesignEnabled = isFeatureEnabled(
      this.props.userAttributes.features,
      "2020-q4-redesign-and-classification-tab"
    );

    const bankId = this.props.match.params.bank_id;
    const accountType = this.props.accountType;
    const accountId = this.props.match.params.account_id;

    return (
      <div className="col-lg-9">
        <ReactTooltip effect="solid" />

        {isNewDesignEnabled && <>
          <AccountTabNav bankId={bankId} accountId={accountId} accountType={accountType} />

          {!this.state.showTransactionsLoader ? (<>
            {!this.state.transactionsError ? (<>
              {this.state.transactions.length > 0 ? (
                <div className="card mt-4 tl-chart">
                  <BarChart
                    labels={this.getRawMonthItems().map((i) => { return this.getMonthShorthand(i.month) })}
                    datasets={this.buildOverviewPayload()}
                    variant="overview"
                  />
                </div>
              ) : (
                <div className="card mt-4 tl-chart">No transactions found</div>
              )}
            </>) : (
              <div className="card mt-4 tl-chart">
                <div className="error">
                  Something went wrong. <a onClick={this.refetchAccountTransactions}>You may want to try again?</a>
                </div>
              </div>
            )}
          </>) : (
            <div className="card mt-4 tl-chart">
              <ContentPageLoader />
            </div>
          )}
        </>}

        {isAlphaVersion() && <div className="card mt-4">
          <div className="row">
            <div className="col col-lg-6">
              <h5>Tokens</h5>
            </div>
          </div>

          {!this.state.showAccessTokenLoader ? (
            <div className="row">
              <div className="col col-lg-6 text-center">
                <button type="button" id="post-debug" className="btn btn-piggy-small text-center" onClick={this.postDebugId}>generate a debug id</button>
              </div>
            </div>
          ) : (
            <ContentPageLoader />
          )}
        </div> }

        <div className="card mt-4">
          <div className="row">
            <div className="col col-lg-6">
              <h5>Balance</h5>
            </div>

            {this.state.balanceFetched && !this.state.balanceError &&
            <div className="col col-lg-6 text-right last-update">
              {this.state.balanceFetched && this.state.balanceFetchedAt &&
              <p>Updated <Moment fromNow>{this.state.balanceFetchedAt}</Moment></p>
              }
            </div>
            }
          </div>

          {!this.state.showBalanceLoader ? (
            <div>
              {!this.state.balanceError ? (
                <div>
                  {n_balances > 0 ? (
                    <div id="balance" className={`row ${n_balances === 2 ? 'double-col': ''}`}>
                      {this.state.balance.hasOwnProperty('available') ? (
                        <div className={`col col-lg-${balance_col_size} text-center`}>
                          <span className={this.state.balance.available < 0 ? "negative-val" : "positive-val"}>
                            {formattedCurrencyAmount(this.state.balance.currency, this.state.balance.available, isNewDesignEnabled)}
                          </span>
                          Available Balance
                        </div>
                      ) : null }

                      <div className={`col col-lg-${balance_col_size} text-center`}>
                        <span className={this.state.balance.current < 0 ? "negative-val" : "positive-val"}>
                          {formattedCurrencyAmount(this.state.balance.currency, this.state.balance.current, isNewDesignEnabled)}
                        </span>
                        Current Balance
                      </div>
                    </div>
                  ) : (
                    <div id="balance" className="row double-col">
                      No data found
                    </div>
                  )}
                </div>
              ) : (
                <div id="balance" className="row">
                  <div className="error">Something went wrong. <a onClick={this.refetchAccountBalance}>You may want to try again?</a></div>
                </div>
              )}
            </div>
          ) : (
            <ContentPageLoader />
          )}
        </div>

        <div className="card mt-4">
          <div className="row">
            <div className="col col-lg-6">
              <h5>Pending Transactions</h5>
            </div>

            {this.state.pendingTransactionsFetched &&
            <div className="col col-lg-6 text-right last-update">
              {this.state.pendingTransactionsFetched && this.state.pendingTransactionsFetchedAt &&
              <p>Updated <Moment fromNow>{this.state.pendingTransactionsFetchedAt}</Moment></p>
              }
            </div>
            }
          </div>

          {!this.state.showPendingTransactionsLoader ? (
            <div>
              {!this.state.pendingTransactionsError ? (
                <div>
                  {this.state.pendingTransactions.length > 0 ? (
                    <div id="transactions" className="row">
                      <table className="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th className="transaction-date">{isNewDesignEnabled ? "Date" : "Time"}</th>
                            {isNewDesignEnabled && <th className="transaction-icon"></th>}
                            <th className="transaction-description">Description</th>
                            <th className="transaction-category">Type</th>
                            <th className="transaction-amount">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pendingTransactions.map((transaction) => {
                            const classificationPrimary = transaction.transaction_classification[0];
                            const iconClass = classificationPrimary ? classificationPrimary.toLowerCase().replace(/[ &]+/g, '-') : null;
                            return (
                              <tr key={transaction.transaction_id}>
                                <td className="transaction-date">{beautyDate(transaction.timestamp)}</td>
                                {isNewDesignEnabled && <td className="transaction-icon"><span className={iconClass}></span></td>}
                                <td className="transaction-description">
                                  {/* If the merchant name is available, show it instead of the transaction description */}
                                  {transaction.merchant_name ? transaction.merchant_name : transaction.description}
                                  {/* If the transaction has been classified, show the classification. Otherwise "" */}
                                  {transaction.transaction_classification ? transaction.transaction_classification.map(value =>
                                    <div key={`${transaction.transaction_id} ${value}`}className="transaction-classification">{value}</div>
                                  ) : ""}
                                </td>
                                <td className="transaction-category">
                                  {transaction.transaction_category ? (
                                    <TransactionCategoryIcon transactionCategory={transaction.transaction_category} />
                                  ) : <TransactionCategoryIcon transactionCategory="UNKNOWN" /> }
                                </td>
                                <td className={"transaction-amount " + (transaction.amount > 0 && "positive-val")}>
                                  {formattedCurrencyAmount(transaction.currency, transaction.amount, isNewDesignEnabled)}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div id="transactions" className="row">
                      <div className="error">No pending transactions found</div>
                    </div>
                  )}
                </div>
              ) : (
                <div id="transactions" className="row">
                  <div className="error">Something went wrong. <a onClick={this.refetchAccountPendingTransactions}>You may want to try again?</a></div>
                </div>
              )}
            </div>
          ) : (
            <ContentPageLoader />
          )}
        </div>

        <div className="card mt-4">
          <div className="row">
            <div className="col col-lg-6">
              <h5>Transactions</h5>
            </div>

            {this.state.transactionsFetched &&
            <div className="col col-lg-6 text-right last-update">
              {this.state.transactionsFetched && this.state.transactionsFetchedAt &&
              <p>Updated <Moment fromNow>{this.state.transactionsFetchedAt}</Moment></p>
              }
            </div>
            }
          </div>

          {!this.state.showTransactionsLoader ? (
            <div>
              {!this.state.transactionsError ? (
                <div>
                  {this.state.transactions.length > 0 ? (
                    <div id="transactions" className="row">
                      <table className="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th className="transaction-date">{isNewDesignEnabled ? "Date" : "Time"}</th>
                            {isNewDesignEnabled && <th className="transaction-icon"></th>}
                            <th className="transaction-description">Description</th>
                            <th className="transaction-category">Type</th>
                            <th className="transaction-amount">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.transactions.map((transaction) => {
                            const classificationPrimary = transaction.transaction_classification[0];
                            const iconClass = classificationPrimary ? classificationPrimary.toLowerCase().replace(/[ &]+/g, '-') : null;
                            return (
                              <tr key={transaction.transaction_id}>
                                <td className="transaction-date">{beautyDate(transaction.timestamp)}</td>
                                {isNewDesignEnabled && <td className="transaction-icon"><span className={iconClass}></span></td>}
                                <td className="transaction-description">
                                  {/* If the merchant name is available, show it instead of the transaction description */}
                                  {transaction.merchant_name ? transaction.merchant_name : transaction.description}
                                  {/* If the transaction has been classified, show the classification. Otherwise "" */}
                                  {transaction.transaction_classification ? transaction.transaction_classification.map((value, index) =>
                                    <div key={`transaction-${index}`} className="transaction-classification">{value}</div>
                                  ) : ""}
                                </td>
                                <td className="transaction-category">
                                  {transaction.transaction_category ? (
                                    <TransactionCategoryIcon transactionCategory={transaction.transaction_category} />
                                  ) : <TransactionCategoryIcon transactionCategory="UNKNOWN" /> }
                                </td>
                                <td className={"transaction-amount " + (transaction.amount > 0 && "positive-val")}>
                                  {formattedCurrencyAmount(transaction.currency, transaction.amount, isNewDesignEnabled)}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>

                      {/* TODO: Fix the "Load more" functionality */}
                      {/* this.state.showMoreTransactionsLoader ? (
                        <ContentPageLoader />
                      ) : (
                        <div className="col-12 text-right">
                          <a className="load-more" onClick={this.loadMoreTransactions}>+ Load more transactions</a>
                        </div>
                      ) */}
                    </div>
                  ) : (
                    <div id="transactions" className="row">No transactions found</div>
                  )}
                </div>
              ) : (
                <div id="transactions" className="row">
                  <div className="error">Something went wrong. <a onClick={this.refetchAccountTransactions}>You may want to try again?</a></div>
                </div>
              )}
            </div>
          ) : (
            <ContentPageLoader />
          )}
        </div>
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired
}
Account.propTypes = propTypes;

function mapStateToProps(state){
  const { user, general, banks } = state;
  const { contentLoading } = general;
  const { isSignedIn, attributes } = user;
  const { items } = banks;

  return {
    contentLoading,
    isSignedIn,
    userAttributes: attributes,
    bankItems: items
  }
}

export default withRouter(connect(mapStateToProps)(Account));
