import React, { Component } from 'react';

import {
  Switch,
  Route
} from 'react-router-dom'


import Callback from './containers/Callback/Callback';
import HandleAuth from './containers/HandleAuth/HandleAuth';

import App from './App';

class AppRouter extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/callback/auth" exact render={(props) => <HandleAuth {...props} />} />
          <Route path="/callback" exact render={(props) => <Callback {...props} />} />
          <Route component={App} />
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
