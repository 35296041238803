import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers/index'

const configureStore = (history, initialState = {}) => {
  //import { routerMiddleware } from 'react-router-redux'
  //const reduxRouterMiddleware = routerMiddleware(history);
  //const middleware = [reduxRouterMiddleware, thunkMiddleware]
  const middleware = [thunkMiddleware]

  const store = createStore(
     rootReducer,
     initialState,
     applyMiddleware(...middleware)
   );

  return store;

}

export default configureStore;
