const formattedCurrencyAmount = (currency, amount, isNewDesignEnabled = true) => {
  const currencySign = currencySignFor(currency);
  const amountWithDecimal = parseFloat(amount).toFixed(2);

  if (isNewDesignEnabled && amountWithDecimal < 0) {
    return `- ${currencySign} ${amountWithDecimal.replace(/^-/, "")}`
  } else {
    return `${currencySign} ${amountWithDecimal}`
  }
}

export default formattedCurrencyAmount;

function currencySignFor(currency) {
  switch(currency) {
    case "GBP":
      return "£"
    case "USD":
      return "$"
    case "EUR":
      return "€"
    default:
      return ""
  }
}
