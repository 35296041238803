import * as types from '../constants/ActionTypes';

const initialState = {
  id: null,
  attributes: {},
  isSignedIn: false,
};

const User = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.USER_LOGIN:
      return {
        ...state,
        id: action.user.id,
        isSignedIn: true,
        attributes: action.user
      };

    case types.USER_UPDATE:
      return {
        ...state,
        attributes: action.user
      };

    case types.USER_LOGOUT:
      return {
        ...state,
        id: null,
        isSignedIn: false,
        attributes: {}
      };

    default:
      return state;
  }
}

export default User;
