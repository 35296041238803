import axios from 'axios';
import auth from './auth';

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

const axiosFetch = axios.create({
  headers: headers,
  timeout: 60000
});

/*
 * axiosFetch is a small "class" on the top of axios
 * at every requests done with axiosFetch, the Authorization Token(id_token)
*/

const authFetch = {
  get: function (url, options = {}) {
    if (auth.isAuthenticated()) {
      axiosFetch.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getIdToken();
    }
    return axiosFetch.get(url, options);
  },

  post: function (url, data, options = {}) {
    if (auth.isAuthenticated()) {
      axiosFetch.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getIdToken();
    }
    return axiosFetch.post(url, data, options);
  },

  put: function (url, options = {}) {
    if (auth.isAuthenticated()) {
      axiosFetch.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getIdToken();
    }
    return axiosFetch.put(url, options);
  },

  patch: function (url, options = {}) {
    if (auth.isAuthenticated()) {
      axiosFetch.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getIdToken();
    }
    return axiosFetch.patch(url, options);
  },

  delete: function (url, options = {}) {
    if (auth.isAuthenticated()) {
      axiosFetch.defaults.headers.common['Authorization'] = 'Bearer ' + auth.getIdToken();
    }
    return axiosFetch.delete(url, options);
  }

};

export default authFetch;
