import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleAuthentication } from '../../actions/User';

import Spinner from '../../components/Spinner/Spinner';

class HandleAuth extends Component {
  componentDidMount() {
    this.handleAuthentication();
  }

  // handle authentification with auth.handleAuthentication()
  handleAuthentication() {
    const { dispatch } = this.props;
    dispatch(handleAuthentication());
  }

  render() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired
}
HandleAuth.propTypes = propTypes;

export default connect()(HandleAuth);
