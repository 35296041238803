import React, { Component } from 'react';
import Spinner from '../../components/Spinner/Spinner';

class PageLoading extends Component {
  acceptTos() {
  }
  render() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
}

export default PageLoading;
