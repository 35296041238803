import * as types from '../constants/ActionTypes';
import authFetch from '../utils/Fetch';

import { hidePageLoading, clearFirstAccountLoaded } from './General';
import { getUsersApiUri } from "../utils/Settings";

function saveBanks(data) {
  return {
    type: types.GET_BANKS,
    items: data
  };
}

function removeBanks() {
  return {
    type: types.DELETE_BANKS
  };
}

function removeBank(bank_id) {
  return {
    type: types.DELETE_BANK,
    bank_id: bank_id
  };
}

export function getBanks() {
  return dispatch => {
    return authFetch.get(`${getUsersApiUri()}/banks`)
      .then((response) => {
        dispatch(saveBanks(response.data));
      })
      .catch((err) => {
        dispatch(hidePageLoading());
      })
  };
}

export function deleteBanks() {
  return (dispatch) => {
    dispatch(removeBanks());
    dispatch(clearFirstAccountLoaded());
  };
}

export function deleteBank(bank_id){
  return (dispatch) => {
    return dispatch(removeBank(bank_id));
  }
}
