import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Switch, Route, withRouter} from "react-router-dom";

import Alert from "./components/Alert/Alert";

import PublicHome from "./containers/PublicHome/PublicHome";
import Authentified from "./containers/Authentified/Authentified";
import TruelayerCallback from "./containers/TruelayerCallback/TruelayerCallback";

import PageLoading from "./containers/PageLoading/PageLoading";

import auth from "./utils/auth";

import {initAuth, logoutUser} from "./actions/User";

import queryString from "query-string";

import "./App.css";

import {
  activeOauthProviders,
  disableOauthProviders,
  activeOpenBankingProviders,
  disableOpenBankingProviders
} from "./actions/General";

class App extends Component {
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(initAuth());

    // manage oauth providers
    const enable_oauth_providers = queryString
      .parse(this.props.location.search)
      .enable_oauth_providers;

    if (enable_oauth_providers === "true" || enable_oauth_providers === true) {
      dispatch(activeOauthProviders());
    } else if (enable_oauth_providers === "false" || enable_oauth_providers === false) {
      dispatch(disableOauthProviders());
    }

    // manage openbanking providers
    const enable_openbanking_providers = queryString
      .parse(this.props.location.search)
      .enable_open_banking_providers;

    if (enable_openbanking_providers === "true" || enable_openbanking_providers === true) {
      dispatch(activeOpenBankingProviders());
    } else if (enable_openbanking_providers === "false" || enable_openbanking_providers === false) {
      dispatch(disableOpenBankingProviders());
    }
  }
  login() {
    auth.login();
  }
  logout() {
    const {dispatch} = this.props;
    dispatch(logoutUser());
  }
  render() {
    const {isAuthenticated} = auth;
    return (
      <div className="App">
        <Alert/> {this.props.pageLoading === true
          ? (<PageLoading/>)
          : (
            <div>
              {!isAuthenticated()
                ? (<PublicHome auth={auth}/>)
                : (
                  <div>
                    {!this.props.isSignedIn && <PageLoading/>}
                    {this.props.isSignedIn && (
                      <div>
                        <Switch>
                          <Route exact path="/truelayer/v1/oauth" component={TruelayerCallback}/>
                          <Route auth={this.props.auth} component={Authentified}/>
                        </Switch>
                      </div>
                    )}
                  </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired
};
App.propTypes = propTypes;

function mapStateToProps(state) {
  const {general, user, banks} = state;

  const {pageLoading} = general;
  const {isSignedIn} = user;
  const {items} = banks;

  return {pageLoading, isSignedIn, bankItems: items};
}

export default withRouter(connect(mapStateToProps)(App));
