import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { autoAlert } from '../../actions/Alert';

import queryString from 'query-string';

class Home extends Component {
  componentDidMount(){
    const disconnected = queryString.parse(this.props.location.search).d;
    if (disconnected && disconnected === "1") {
      const { dispatch } = this.props;
      dispatch(autoAlert('You have disconnected your bank account.', 'success', 'Success!'));
    }
  }
  render() {
    return (
      <div>
        {this.props.bankItems.length > 0 && this.props.firstBankIdLoaded !== "" && this.props.firstAccountIdLoaded !== "" ? (
          <Redirect to={`/banks/${this.props.firstBankIdLoaded}/accounts/${this.props.firstAccountIdLoaded}`} />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
const propTypes = {
  dispatch: PropTypes.func.isRequired
}
Home.propTypes = propTypes;

function mapStateToProps(state){
  const { banks, general } = state;
  const { items, banksFetched } = banks;
  const { firstAccountIdLoaded, firstBankIdLoaded } = general;

  return {
    bankItems: items,
    banksFetched,
    firstBankIdLoaded,
    firstAccountIdLoaded
  }
}

export default withRouter(connect(mapStateToProps)(Home));
