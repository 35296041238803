export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_TOS = "USER_UPDATE_TOS";

export const SHOW_PAGE_LOADING = "SHOW_PAGE_LOADING";
export const HIDE_PAGE_LOADING = "HIDE_PAGE_LOADING";
export const SHOW_ACCOUNTS_LOADING = "SHOW_ACCOUNTS_LOADING";
export const HIDE_ACCOUNTS_LOADING = "HIDE_ACCOUNTS_LOADING";
export const SHOW_CONTENT_LOADING = "SHOW_CONTENT_LOADING";
export const HIDE_CONTENT_LOADING = "HIDE_CONTENT_LOADING";
export const SHOW_SUBMIT_LOADING = "SHOW_SUBMIT_LOADING";
export const HIDE_SUBMIT_LOADING = "HIDE_SUBMIT_LOADING";

export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";

export const SET_FIRST_ACCOUNT_LOADED = "SET_FIRST_ACCOUNT_LOADED";
export const CLEAR_FIRST_ACCOUNT_LOADED = "CLEAR_FIRST_ACCOUNT_LOADED";

export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_HIDE = "ALERT_HIDE";

export const GET_BANKS = "GET_BANKS";
export const DELETE_BANKS = "DELETE_BANKS";
export const DELETE_BANK = "DELETE_BANK";

export const ACTIVE_OPENBANKING_PROVIDERS = "ACTIVE_OPENBANKING";
export const DISABLE_OPENBANKING_PROVIDERS = "DISABLE_OPENBANKING";

export const ACTIVE_OAUTH_PROVIDERS = "ACTIVE_OAUTH_PROVIDERS";
export const DISABLE_OAUTH_PROVIDERS = "DISABLE_OAUTH_PROVIDERS";

export const ENABLE_MOCK_BANK = "ENABLE_MOCK_BANK";
export const DISABLE_MOCK_BANK = "DISABLE_MOCK_BANK";
