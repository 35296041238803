import React, { Component } from 'react';
import { connect } from 'react-redux';

import auth from '../../utils/auth.js';

class PublicHome extends Component {
  constructor(props) {
    super(props);

    this.loginBtn = this.loginBtn.bind(this);
    this.signupBtn = this.signupBtn.bind(this);
  }
  loginBtn(event) {
    event.preventDefault()
    auth.login();
  }
  signupBtn(event) {
    event.preventDefault()
    auth.signup();
  }
  render() {
    return (
      <div className="">
        <section id="gradient-bg">
            <div id="bg-logo">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-sm-6 col-centered">
                            <h1>Watch <br/>our API <br/>in action.</h1>
                            <a onClick={this.loginBtn} className="btn btn-signin">Sign in</a>
                            <p>Don't have an account?<br/>
                                <a onClick={this.signupBtn} className="signup-link">Sign up</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }
}

export default connect()(PublicHome);
