import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Spinner.css';

class Spinner extends Component {
  render() {
    return (
      <div className="Spinner">
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      </div>
    );
  }
}
const propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
}
Spinner.propTypes = propTypes;

export default Spinner;
