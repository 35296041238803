import * as types from "../constants/ActionTypes";
import auth from "../utils/auth";
import history from "../history";
import authFetch from "../utils/Fetch";
import Bugsnag from "bugsnag-js";

import { showPageLoading, hidePageLoading } from "./General";
import { autoAlert } from "./Alert";
import { getBanks } from "./Banks";
import { getUsersApiUri } from "../utils/Settings";

function authUser(user) {
  return {
    type: types.USER_LOGIN,
    user: user
  };
}

function updateUserState(user) {
  return {
    type: types.USER_UPDATE,
    user: user
  };
}

function authLogoutUser() {
  return {
    type: types.USER_LOGOUT
  };
}

export function handleAuthentication() {
  return dispatch => {
    dispatch(handleAuthServer());
  };
}

export function handleAuthServer() {
  return dispatch => {
    authFetch
      .post(`${getUsersApiUri()}/users`)
      .then(response => {
        return Promise.all([
          dispatch(authUser(response.data)),
          dispatch(setBugsnagUser())
        ]).then(() => history.replace("/"));
      })
      .catch(err => {
        const metadata = { "special info": { response: err } };
        Bugsnag.notifyException(
          err,
          "User can't be created on server",
          metadata,
          "error"
        );
        dispatch(autoAlert("Something broke!", "warning", "Ooops!"));
        dispatch(logoutUser());
      });
  };
}

export function initAuth() {
  return async (dispatch, getState) => {
    const { user } = getState();

    if (!user.isSignedIn) {
      if (auth.isAuthenticated()) {
        try {
          await dispatch(showPageLoading());
          await dispatch(getAuthFromServer());
          await dispatch(renewTokens());
          await dispatch(getBanks());
          await dispatch(hidePageLoading());
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
}

export function getAuthFromServer(authentication_token, email) {
  return dispatch => {
    return authFetch
      .get(`${getUsersApiUri()}/users/me`)
      .then(response => {
        const user = response.data;
        dispatch(authUser(response.data)); //save on the state
        dispatch(setBugsnagUser(user));
      })
      .catch(err => {
        const metadata = { "special info": { response: err } };
        Bugsnag.notifyException(
          err,
          "Can't initialize user from server",
          metadata,
          "error"
        );
        dispatch(autoAlert("Something broke", "warning", "Ooops!"));
        dispatch(logoutUser());
        dispatch(hidePageLoading());
      });
  };
}

export function renewTokens() {
  return dispatch => {
    return authFetch
      .post(`${getUsersApiUri()}/renew_tokens`)
      .catch(err => {
        dispatch(autoAlert("Something broke", "warning", "Ooops!"));
      });
  };
}

export function updateUser(data, redirect = true) {
  return dispatch => {
    dispatch(updateUserState(data));

    dispatch(setBugsnagUser(data));
    if (redirect) {
      history.replace("/");
    }
  };
}

export function logoutUser(customRedirect = "") {
  return (dispatch, getState) => {
    dispatch(authLogoutUser());
    dispatch(unsetBugsnagUser());
    auth.logout();
    if (customRedirect !== "") {
      if (/^((http|https):\/\/)/.test(customRedirect)) {
        window.location.href = customRedirect;
      } else {
        history.replace(customRedirect);
      }
    } else {
      history.replace("/");
    }
  };
}

export function unsetBugsnagUser(user) {
  return (dispatch, getState) => {
    Bugsnag.user = {};
  };
}

export function setBugsnagUser() {
  return (dispatch, getState) => {
    const { user } = getState();
    Bugsnag.user = {
      id: user.id,
      ...(user.name ? { name: user.name } : { name: "" }),
      ...(user.email ? { email: user.email } : { email: "" })
    };
  };
}
