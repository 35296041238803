import * as types from "../constants/ActionTypes";

const initialState = {
  pageLoading: false,
  accountsLoading: false,
  contentLoading: false,
  currentAccount: {},
  firstAccountIdLoaded: "",
  firstBankIdLoaded: "",
  oauth_providers: false,
  openbanking_active: true,
  enable_mock_bank: false,
};

const general = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SHOW_PAGE_LOADING:
      return {
        ...state,
        pageLoading: true
      };

    case types.HIDE_PAGE_LOADING:
      return {
        ...state,
        pageLoading: false
      };

    case types.SHOW_ACCOUNTS_LOADING:
      return {
        ...state,
        accountsLoading: true
      };

    case types.HIDE_ACCOUNTS_LOADING:
      return {
        ...state,
        accountsLoading: true
      };

    case types.SHOW_CONTENT_LOADING:
      return {
        ...state,
        contentLoading: true
      };

    case types.HIDE_CONTENT_LOADING:
      return {
        ...state,
        contentLoading: false
      };

    case types.SET_CURRENT_ACCOUNT:
      return {
        ...state,
        currentAccount: action.account
      };

    case types.SET_FIRST_ACCOUNT_LOADED:
      return {
        ...state,
        firstAccountIdLoaded: action.account_id,
        firstBankIdLoaded: action.bank_id
      };
    case types.CLEAR_FIRST_ACCOUNT_LOADED:
      return {
        ...state,
        firstAccountIdLoaded: "",
        firstBankIdLoaded: ""
      };

    case types.ACTIVE_OPENBANKING_PROVIDERS:
      return {
        ...state,
        openbanking_active: true
      };

    case types.DISABLE_OPENBANKING_PROVIDERS:
      return {
        ...state,
        openbanking_active: false
      };

    case types.ACTIVE_OAUTH_PROVIDERS:
      return {
        ...state,
        oauth_providers: true
      };

    case types.DISABLE_OAUTH_PROVIDERS:
      return {
        ...state,
        oauth_providers: false
      };

    case types.ENABLE_MOCK_BANK:
      return {
        ...state,
        enable_mock_bank: true
      };

    case types.DISABLE_MOCK_BANK:
      return {
        ...state,
        enable_mock_bank: false
      }

    default:
      return state;
  }
};

export default general;
