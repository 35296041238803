import React from 'react';

const TransactionCategoryIcon = (props) => {
    let iconClass = "";
    let tooltipText = "";
    if (props.transactionCategory === "ATM") {
        iconClass = "atm";
        tooltipText = "ATM";
    } else if (props.transactionCategory === "BILL_PAYMENT") {
        iconClass = "bill";
        tooltipText = "Bill";
    } else if (props.transactionCategory === "CASH") {
        iconClass = "cash";
        tooltipText = "Cash";
    } else if (props.transactionCategory === "CASHBACK") {
        iconClass = "cashback";
        tooltipText = "Cashback";
    } else if (props.transactionCategory === "CHEQUE") {
        iconClass = "cheque";
        tooltipText = "Cheque";
    } else if (props.transactionCategory === "CORRECTION") {
        iconClass = "correction";
        tooltipText = "Correction";
    } else if (props.transactionCategory === "CREDIT") {
        iconClass = "credit";
        tooltipText = "Credit";
    } else if (props.transactionCategory === "DIRECT_DEBIT") {
        iconClass = "direct-debit";
        tooltipText = "Direct Debit";
    } else if (props.transactionCategory === "DEBIT") {
        iconClass = "debit";
        tooltipText = "Debit";
    } else if (props.transactionCategory === "DIVIDEND") {
        iconClass = "dividends";
        tooltipText = "Dividends";
    } else if (props.transactionCategory === "FEE_CHARGE") {
        iconClass = "fee";
        tooltipText = "Fee or Charge";
    } else if (props.transactionCategory === "INTEREST") {
        iconClass = "interests";
        tooltipText = "Interest";
    } else if (props.transactionCategory === "OTHER") {
        iconClass = "other";
        tooltipText = "Other";
    } else if (props.transactionCategory === "PURCHASE") {
        iconClass = "purchase";
        tooltipText = "Purchase";
    } else if (props.transactionCategory === "STANDING_ORDER") {
        iconClass = "standing-order";
        tooltipText = "Standing Order";
    } else if (props.transactionCategory === "TRANSFER") {
        iconClass = "transfer";
        tooltipText = "Transfer";
    } else if (props.transactionCategory === "UNKNOWN") {
        iconClass = "unknown";
        tooltipText = "Unknown";
    }

    return (
        <div className={`transaction-type ${iconClass}`}>
            {tooltipText}
        </div>
    )
}

export default TransactionCategoryIcon;
