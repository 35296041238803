import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, Bar } from "react-chartjs-2";

class BarChart extends Component {
  render() {
    setRoundedCorners(this.props.variant);

    return (
      <Bar
        data={{
          labels: this.props.labels,
          datasets: this.props.datasets
        }}
        width={100}
        height={30}
        options={getChartOptions(this.props.variant)}
      />
    );
  }
}

const propTypes = {
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired
}
BarChart.propTypes = propTypes;

export default BarChart;

function getChartOptions(variant) {
  const axesStacked = variant === "overview" ? false : true

  return {
    animation: {
      duration: 0
    },
    cornerRadius: 3,
    legend: {
      position: "right",
      align: "end",
      labels: {
        boxWidth: 12,
        fontFamily: "'proxima-nova', sans-serif",
        fontColor: "#5B6871",
        fontSize: 12
      }
    },
    scales: {
      yAxes: [{
        gridLines: {
          drawBorder: false,
          tickMarkLength: 0,
          color: "rgba(245, 247, 248, 1)",
          zeroLineColor: "rgba(245, 247, 248, 1)"
        },
        stacked: axesStacked,
        ticks: {
          padding: 15,
          fontFamily: "'proxima-nova', sans-serif",
          fontColor: "#485465",
          fontSize: 10,
          maxTicksLimit: 6
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        },
        stacked: axesStacked,
        ticks: {
          fontFamily: "'proxima-nova', sans-serif",
          fontColor: "#485465",
          fontSize: 10
        }
      }]
    },
    tooltips: {
      mode: "index",
      intersect: false
    }
  }
}

// Function to redraw bar rectangles so that they have rounded corners
// See: https://github.com/reactchartjs/react-chartjs-2/issues/364#issuecomment-458713421
function setRoundedCorners(variant) {
  Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw() {
      const { ctx } = this._chart;
      const vm = this._view;
      let { borderWidth } = vm;
      let left, right, top, bottom, signX, signY, borderSkipped, radius;

      // If radius is less than 0 or is large enough to cause drawing errors a max
      // radius is imposed. If cornerRadius is not defined set it to 0.
      let { cornerRadius } = this._chart.config.options;
      if (cornerRadius < 0) { cornerRadius = 0; }

      if (typeof cornerRadius === 'undefined') { cornerRadius = 0; }

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        const halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |
      const corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
      ];

      // Find first (starting) corner with fallback to 'bottom'
      const borders = ['bottom', 'left', 'top', 'right'];
      let startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      let corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (let i = 1; i < 4; i += 1) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId === 4) {
          nextCornerId = 0;
        }

        const width = corners[2][0] - corners[1][0];
        const height = corners[0][1] - corners[1][1];
        const x = corners[1][0];
        const y = corners[1][1];

        radius = cornerRadius;
        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
          radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
          radius = Math.floor(Math.abs(width) / 2);
        }

        // Detect first and last bars to apply rounded corners
        let firstVisible = 0, lastVisible = 0;
        let datasetsLength = this._chart.data.datasets.length;
        for (let findFirst = 0; findFirst < datasetsLength; findFirst++) {
          if (!this._chart.getDatasetMeta(findFirst).hidden) {
            firstVisible = findFirst;
            break;
          }
        }
        let roundedBottom = this._datasetIndex === firstVisible;
        for (let findLast = 0; findLast < datasetsLength; findLast++) {
          if (!this._chart.getDatasetMeta(findLast).hidden) {
            lastVisible = findLast;
          }
        }
        let roundedTop = this._datasetIndex === lastVisible;

        if ((roundedBottom && roundedTop) || variant === "overview") {
          // All corners rounded
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
        } else if (roundedBottom) {
          ctx.moveTo(x, y);
          ctx.lineTo(x + width, y);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x , y + height);
          ctx.lineTo(x, y );
        } else if (roundedTop) {
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height);
          ctx.lineTo(x, y + height);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
        } else {
          ctx.moveTo(x, y);
          ctx.lineTo(x + width, y);
          ctx.lineTo(x + width, y + height );
          ctx.lineTo(x , y + height);
          ctx.lineTo(x, y );
        }
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
    },
  });
}
