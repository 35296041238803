import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux';

import { setCurrentAccount } from '../../actions/General';
import displayableCardNetwork from '../../utils/DisplayableCardNetwork';

class SidebarAccountItem extends Component {
  constructor(props){
    super(props);

    this.loadCurrentAccount = this.loadCurrentAccount.bind(this);
  }
  componentDidMount(){
    this.loadCurrentAccount();
  }
  componentDidUpdate(prevProps, prevState){
    this.loadCurrentAccount();
  }
  loadCurrentAccount(){
    const url = `/banks/${this.props.bank.id}/${this.props.accountType}s/${this.props.account.account_id}`;
    if (url === window.location.pathname){
      const { dispatch } = this.props;
      dispatch(setCurrentAccount(this.props.account));
    }
  }
  render(){
    return (
      <NavLink to={`/banks/${this.props.bank.id}/${this.props.accountType}s/${this.props.account.account_id}`} activeClassName="active" className={this.props.customClasses}>
        {this.props.account.display_name}
        {this.props.account.account_number &&
          <span>{this.props.account.account_number.number}</span>
        }
        {this.props.account.card_network &&
          <span>
            {displayableCardNetwork(this.props.account.card_network) ? (
              this.props.account.card_network
            ) : ""}
            {this.props.account.partial_card_number ? ` *${this.props.account.partial_card_number}` : ""}
          </span>
        }
      </NavLink>
    );
  }
}

const propTypes = {
  account: PropTypes.object.isRequired,
  customClasses: PropTypes.string
}
SidebarAccountItem.propTypes = propTypes;

export default withRouter(connect()(SidebarAccountItem));
