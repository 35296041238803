import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink, NavItem, DropdownToggle, DropdownMenu, UncontrolledNavDropdown } from 'reactstrap';

import { logoutUser } from '../../actions/User';

import './HeaderNavbar.css';

class HeaderNavbar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    this.logout = this.logout.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout(event){
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(logoutUser());
  }

  render() {
    return (
      <div>
        <Navbar color="inverse" inverse toggleable className="navbar navbar-expand-lg navbar-dark fixed-top true-navbar">
          <NavbarBrand href="/">DEMO</NavbarBrand>
          <NavbarToggler onClick={this.toggle} right />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="nav-item-btn">
                <NavLink className="btn btn-primary btn-lg" href="https://console.truelayer.com" target="_blank">Go to console</NavLink>
              </NavItem>
              <UncontrolledNavDropdown className="user-dropdown">
                <DropdownToggle caret nav>
                  <img src={this.props.userAttributes.picture} className="user-avatar" alt="Avatar" />
                </DropdownToggle>
                <DropdownMenu>
                  <ul>
                    <li className="user-email">{this.props.userAttributes.email}</li>
                    <li className="signout"><a onClick={this.logout}>Sign out</a></li>
                  </ul>
                </DropdownMenu>
              </UncontrolledNavDropdown>
              <NavItem className="user-mobile-logout">
                <NavLink onClick={this.logout}>Logout</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  userAttributes: PropTypes.object.isRequired
}
HeaderNavbar.propTypes = propTypes;

export default withRouter(connect()(HeaderNavbar));
