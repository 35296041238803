import * as types from '../constants/ActionTypes';

const initialState = {
  alertTitle: null,
  alertMessage: null,
  alertType: null
};

const alert = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ALERT_SHOW:
      return {
        ...state,
        alertTitle: action.alertTitle,
        alertMessage: action.alertMessage,
        alertType: action.alertType
      };

    case types.ALERT_HIDE:
      return {
        ...state,
        alertTitle: null,
        alertMessage: null,
        alertType: null
      };

    default:
      return state;
  }
}

export default alert;
