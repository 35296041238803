import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { hideAlert } from '../../actions/Alert';
import './Alert.css';
import isFeatureEnabled from "../../utils/isFeatureEnabled";

class Alert extends Component {
  constructor(props) {
    super(props);
    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert(){
    const { dispatch } = this.props;
    dispatch(hideAlert());
  }

  render() {
    const className = this.props.alertType ? 'alert alert--' + this.props.alertType : 'flash'

    const isNewDesignEnabled = this.props.userAttributes.features ?
      isFeatureEnabled(
        this.props.userAttributes.features,
        "2020-q4-redesign-and-classification-tab"
      ) : false;

    return (
      <div className={isNewDesignEnabled ? "redesign-2020" : ""}>
        {this.props.alertMessage && this.props.alertType ?
          <div className={className}>
            <div className="alert-message">
              {this.props.alertTitle && <h5>{this.props.alertTitle}</h5>}
              <span>{this.props.alertMessage}</span>
              <button type="button" className="alert-close" onClick={this.closeAlert}>Close</button>
            </div>
          </div>
        : <></>}
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  alertTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string
}
Alert.propTypes = propTypes;

function mapStateToProps(state){
  const { alert, user } = state;
  const { alertTitle, alertMessage, alertType } = alert;
  const { attributes } = user;

  return {
    alertTitle,
    alertMessage,
    alertType,
    userAttributes: attributes
  }
}
export default withRouter(connect(mapStateToProps)(Alert));
