import * as types from '../constants/ActionTypes';

function alertShow(alertMessage, alertType, alertTitle) {
  return {
    type: types.ALERT_SHOW,
    alertTitle: alertTitle,
    alertMessage: alertMessage,
    alertType: alertType
  };
}

function alertHide() {
  return {
    type: types.ALERT_HIDE
  };
}

export function autoAlert(alertMessage, alertType, alertTitle = null) {
  return dispatch => {
    dispatch(showAlert(alertMessage, alertType, alertTitle))
    setTimeout(() => {
      dispatch(hideAlert())
    }, 5000000)
  };
}

export function showAlert(alertMessage, alertType, alertTitle = null) {
  return dispatch => {
    dispatch(alertShow(alertMessage, alertType, alertTitle))
  };
}

export function hideAlert() {
  return (dispatch) => {
    return dispatch(alertHide());
  };
}
