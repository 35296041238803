import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from 'bugsnag-js';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import authFetch from '../../utils/Fetch';

import { autoAlert } from '../../actions/Alert';

import Spinner from '../../components/Spinner/Spinner';
import { getUsersApiUri } from "../../utils/Settings";

import queryString from 'query-string';

class TruelayerCallback extends Component {
  componentDidMount() {
    this.handleCallback();
  }
  // handle handleCallback
  handleCallback() {
    const error = queryString.parse(this.props.location.search).error;
    if (!error){
      const code = queryString.parse(this.props.location.search).code;
      const { dispatch } = this.props;
      authFetch.post(`${getUsersApiUri()}/banks`, { code: code })
        .then((response) => {
          if (response.data.bank_id && response.data.account_id){
            this.closePopup(response.data.bank_id, response.data.account_id);
          } else {
            this.closePopup();
          }
        })
        .catch((err) => {
          if (err.response && err.response.data === "bank already added") {
            const metadata = { "special info": { response: err.response } };
            Bugsnag.notifyException(err, "Error during truelayer callback", metadata, "error");
            dispatch(autoAlert('Bank already added with the same credentials! You may want to try again?', 'warning', 'Ooops!'));
          } else {
            const metadata = { "special info": { response: err.response } };
            Bugsnag.notifyException(err, "Error during truelayer callback", metadata, "error");
            dispatch(autoAlert('Something went wrong. You may want to try again ?', 'warning', 'Ooops!'));
          }
        });
    } else if (error === 'access_denied'){
      window.close();
    } else {
      window.close();
    }

  }
  closePopup(bank_id = null, account_id = null) {
    if (window.opener) {
      if (bank_id && account_id) {
        window.opener.document.location.href =
          "/banks/" + bank_id + "/accounts/" + account_id;
        window.close();
      } else {
        window.opener.document.location.href = "/";
        window.close();
      }
    } else {
      if (bank_id && account_id) {
        window.document.location.href =
          "/banks/" + bank_id + "/accounts/" + account_id;
      } else {
        window.document.location.href = "/";
      }
    }
  }
  render() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
}
const propTypes = {
  dispatch: PropTypes.func.isRequired
}
TruelayerCallback.propTypes = propTypes;

function mapStateToProps(state){
  const { banks } = state;

  const { items } = banks;

  return {
    bankItems: items
  }
}

export default withRouter(connect(mapStateToProps)(TruelayerCallback));
