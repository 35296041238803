import React, {Component} from "react";
import PropTypes from "prop-types";

import {connect} from "react-redux";
import {withRouter, NavLink} from "react-router-dom";

import SidebarAccountItem from "../SidebarAccountItem/SidebarAccountItem";
import ContentPageLoader from "../ContentPageLoader/ContentPageLoader";

import authFetch from "../../utils/Fetch";

import ReactTooltip from "react-tooltip";

import {deleteBank} from "../../actions/Banks";
import {autoAlert} from "../../actions/Alert";
import {setCurrentAccount, setFirstAccountLoaded} from "../../actions/General";
import { getUsersApiUri } from "../../utils/Settings";

import Bugsnag from "bugsnag-js";

import closeIcon from "../../assets/images/close.svg";

class SidebarBankItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingBank: false,
      scopes: [],
      accounts: [],
      cards: [],
      errorMessage: false
    };

    this.loadBankMetadata = this
      .loadBankMetadata
      .bind(this);

    this.loadAccessToken = this
      .loadAccessToken
      .bind(this);

    this.loadBankAccounts = this
      .loadBankAccounts
      .bind(this);
    this.loadBankCards = this
      .loadBankCards
      .bind(this);

    this.setFirstAccount = this
      .setFirstAccount
      .bind(this);

    this.disconnectBank = this
      .disconnectBank
      .bind(this);

    this.reloadBank = this
      .reloadBank
      .bind(this);
  }
  async componentDidMount() {
    this.setState({isLoadingBank: true, errorMessage: false});
    try {
      const bankMetadata = await this.loadBankMetadata(this.props.bank.id);

      if (bankMetadata.scopes.includes("accounts")) {
        this.loadBankAccounts(this.props.bank.id);
      }
      if (bankMetadata.scopes.includes("cards")) {
        this.loadBankCards(this.props.bank.id);
      }

      // request for the access_token too
      await this.loadAccessToken(this.props.bank.id);
    } catch (e) {
      this.setState({isLoadingBank: false});
    }
    this.setState({isLoadingBank: false});
  }
  componentWillUpdate(nextProps, nextState) {}
  loadBankMetadata() {
    return authFetch
      .get(`${getUsersApiUri()}/banks/${this.props.bank.id}/metadata`)
      .then(response => {
        this.setState({scopes: response.data.results[0].scopes, errorMessage: false});
        return response.data.results[0];
      })
      .catch(err => {
        console.log(err);
        this.setState({errorMessage: true});
      });
  }
  loadAccessToken() {
    return authFetch.get(`${getUsersApiUri()}/banks/${this.props.bank.id}/access_token`)
  }
  loadBankAccounts() {
    return authFetch
      .get(`${getUsersApiUri()}/banks/${this.props.bank.id}/accounts`)
      .then(response => {
        this.setState({accounts: response.data, errorMessage: false});
        this.setFirstAccount(response.data);
      })
      .catch(err => {
        console.log(err);
        this.setState({errorMessage: true});
      });
  }
  loadBankCards() {
    return authFetch
      .get(`${getUsersApiUri()}/banks/${this.props.bank.id}/cards`)
      .then(response => {
        this.setState({cards: response.data, errorMessage: false});
      })
      .catch(err => {
        console.log(err);
        this.setState({errorMessage: true});
      });
  }

  setFirstAccount(data) {
    if (this.props.firstBankIdLoaded === "" || this.props.firstAccountIdLoaded === "") {
      const {dispatch} = this.props;

      dispatch(setFirstAccountLoaded(this.props.bank.id, data[0].account_id));
    }
  }

  disconnectBank() {
    const bank_id = this.props.bank.id;
    const {dispatch} = this.props;

    authFetch
      .delete(`${getUsersApiUri()}/banks/${this.props.bank.id}`)
      .then(response => {
        Promise
          .all([dispatch(deleteBank(bank_id))])
          .then(() => {
            window.location.href = "/?d=1";
          });
      })
      .catch(err => {
        const metadata = {
          "special info": {
            response: err.response
          }
        };
        Bugsnag.notifyException(err, "Error disconneting bank account", metadata, "error");
        dispatch(autoAlert("Something went wrong. You may want to try again ?", "warning", "Ooops!"));
      });
  }
  reloadBank(event) {
    event.preventDefault();

    this.setState({isLoadingBank: true, errorMessage: false});
    new Promise((resolve, reject) => {
      this.loadBankAccounts(this.props.bank.id);
    }).then(() => {
      this.loadBankCards(this.props.bank.id);
    }).then(() => {
      this.setState({isLoadingBank: false});
    });
  }

  render() {
    let bankName = "";
    let bankIcon = "";
    if (this.state.accounts.length > 0 || this.state.cards.length > 0) {
      if (this.state.accounts.length > 0) {
        bankName = this.state.accounts[0].provider.display_name;
        bankIcon = this.state.accounts[0].provider.logo_uri;
      } else {
        bankName = this.state.cards[0].provider.display_name;
        bankIcon = this.state.cards[0].provider.logo_uri;
      }
    }

    // for show bank name in Personal Info...
    if (window.location.pathname === `/banks/${this.props.bank.id}/info` && (this.state.accounts.length > 0 || this.state.cards.length)) {
      let bankDisplayName = "";

      if (this.state.accounts.length > 0) {
        bankDisplayName = this.state.accounts[0].provider.display_name;
      } else {
        bankDisplayName = this.state.cards[0].provider.display_name;
      }
      const {dispatch} = this.props;
      dispatch(setCurrentAccount({
        display_name: "Personal Info",
        provider: {
          display_name: bankDisplayName
        }
      }));
    }

    return (
      <div className="list-group-section">
        <div
          className="list-group-item bank-info"
          style={bankIcon
          ? {
            background: `#eeeeee url("${bankIcon}") no-repeat 30px center / 25px`
          }
          : {}}>
          {this.state.errorMessage && this.state.accounts.length === 0 && this.state.cards.length === 0 && !this.state.isLoadingBank
            ? (
              <div>
                <span>error</span>
                <a
                  className="close-account"
                  onClick={this.disconnectBank}
                  data-tip="Disconnect this Bank and remove it from Piggy Bank.">
                  <img src={closeIcon} alt="Disconnect bank account"/>
                </a>
                <ReactTooltip effect="solid"/>
              </div>
            )
            : (
              <div>
                {this.state.isLoadingBank
                  ? ("Loading...")
                  : (
                    <div>
                      <span>{bankName}</span>
                      <a
                        className="close-account"
                        onClick={this.disconnectBank}
                        data-tip="Disconnect this Bank and remove it from Piggy Bank.">
                        <img src={closeIcon} alt="Disconnect bank account"/>
                      </a>
                      <ReactTooltip effect="solid"/>
                    </div>
                  )}
              </div>
            )}
        </div>
        {this.state.isLoadingBank
          ? (
            <div className="list-group-section">
              <ContentPageLoader/>
            </div>
          )
          : (
            <div>
              {this.state.errorMessage && this.state.accounts.length === 0 && this.state.cards.length === 0
                ? (
                  <div className="list-group-section">
                    <span className="error">
                      Something went wrong.{" "}
                      <a onClick={this.reloadBank}>You may want to try again?</a>
                    </span>
                  </div>
                )
                : (
                  <div className="list-group-section">
                    {this
                      .state
                      .accounts
                      .map((account, i) => {
                        return (<SidebarAccountItem
                          key={account.account_id}
                          customClasses={i === 0
                          ? "list-item bankaccount first"
                          : "list-item bankaccount"}
                          account={account}
                          bank={this.props.bank}
                          accountType="account"/>);
                      })}

                    {this
                      .state
                      .cards
                      .map((account, i) => {
                        return (<SidebarAccountItem
                          key={account.account_id}
                          customClasses={i === 0 && this.state.accounts.length === 0
                          ? "list-item creditcard first"
                          : "list-item creditcard"}
                          account={account}
                          bank={this.props.bank}
                          accountType="card"/>);
                      })}
                    {this.state.scopes && this
                      .state
                      .scopes
                      .includes("info") && (
                      <NavLink
                        to={`/banks/${this.props.bank.id}/info`}
                        className={this.state.accounts.length === 0 && this.state.cards.length === 0
                        ? "list-item personalinfo personalinfo--no-border"
                        : "list-item personalinfo"}
                        activeClassName="active">
                        Personal Info
                      </NavLink>
                    )}
                  </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

const propTypes = {
  bank: PropTypes.object.isRequired
};
SidebarBankItem.propTypes = propTypes;

function mapStateToProps(state) {
  const {general} = state;

  const {firstBankIdLoaded, firstAccountIdLoaded} = general;

  return {firstBankIdLoaded, firstAccountIdLoaded};
}

export default withRouter(connect(mapStateToProps)(SidebarBankItem));
