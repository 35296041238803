import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import displayableCardNetwork from '../../utils/DisplayableCardNetwork';
import isFeatureEnabled from "../../utils/isFeatureEnabled";

class SubHeader extends Component {
  render() {
    const newDesignEnabled = isFeatureEnabled(
      this.props.userAttributes.features,
      "2020-q4-redesign-and-classification-tab"
    );

    const greeting = (newDesignEnabled && !this.props.userAttributes.name.includes("@")) ?
      `Hello, ${this.props.userAttributes.name.split(" ")[0]}!` : "Hello!";

    return (
      <div id="background-header" className="container-fluid">
        <div className="container">
          {this.props.bankItems.length > 0 ? (
            <div className="row">
              <div id="logo" className="col-lg-3"></div>

              <div id="info" className="col-lg-9">
                <div className="row">
                  <div className="col-lg-4 hello-box">
                    <h2>{greeting}</h2>
                  </div>

                  {Object.keys(this.props.currentAccount).length > 0 &&
                  <div className="col-lg-8 bank-box">
                    <h3>{this.props.currentAccount.provider.display_name}</h3>

                    <h4>
                      <span>{this.props.currentAccount.display_name}</span>

                      {this.props.currentAccount.account_number &&
                      <span className="account-details">
                        {this.props.currentAccount.account_number.number ?  (
                          <span>
                            <span className="account-details-separator">/</span>
                            <span>{this.props.currentAccount.account_number.number}</span>
                          </span>
                        ) : ""}

                        {this.props.currentAccount.account_number.sort_code ?  (
                          <span>
                            <span className="account-details-separator">/</span>
                            <span>{this.props.currentAccount.account_number.sort_code}</span>
                          </span>
                        ) : ""}
                      </span>
                      }

                      {this.props.currentAccount.partial_card_number &&
                        <span className="account-details">
                          {displayableCardNetwork(this.props.currentAccount.card_network) ? (
                            <span>
                              <span className="account-details-separator">/</span>
                              <span>{this.props.currentAccount.card_network}</span>
                            </span>
                          ) : ""}

                          {this.props.currentAccount.partial_card_number ?
                            " *" + this.props.currentAccount.partial_card_number : ""}
                        </span>
                      }
                    </h4>

                    {this.props.currentAccount.account_number?.iban ?  (
                          <h4>
                            <span>
                              <span>{this.props.currentAccount.account_number.iban}</span>
                            </span>
                          </h4>
                    ) : ""} 
                  </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="row"></div>
          )}
        </div>
      </div>
    );
  }
}

const propTypes = {
  userAttributes: PropTypes.object.isRequired,
  bankItems: PropTypes.array,
  currentAccount: PropTypes.object.isRequired
}
SubHeader.propTypes = propTypes;

function mapStateToProps(state){
  const { general } = state;
  const { currentAccount } = general;

  return {
    currentAccount
  }
}

export default withRouter(connect(mapStateToProps)(SubHeader));
