import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';

import ContentPageLoader from '../../components/ContentPageLoader/ContentPageLoader';
import AccountTabNav from "../../components/AccountTabNav/AccountTabNav";
import BarChart from "../../components/BarChart/BarChart";

import { autoAlert } from '../../actions/Alert';
import { logoutUser } from '../../actions/User';

import authFetch from '../../utils/Fetch';
import formattedCurrencyAmount from "../../utils/FormattedCurrencyAmount"
import { getUsersApiUri } from "../../utils/Settings";

class AccountClassification extends Component {
  constructor(props){
    super(props);

    this.state = {
      transactions: [],
      classificationByMonth: [],
      transactionsFetched: false,
      showTransactionsLoader: false,
      transactionsError: false,
      showMoreTransactionsLoader: false,
      transactionsToDate: "",
      transactionsFetchedAt: null,
      showAccessTokenLoader:false,
      accessToken : ""
    };

    this.fetchAccessToken = this.fetchAccessToken.bind(this);
    this.fetchAccountTransactions = this.fetchAccountTransactions.bind(this);
    this.refetchAccountTransactions = this.refetchAccountTransactions.bind(this);
    this.getMonthShorthand = this.getMonthShorthand.bind(this);
    this.getMonthName = this.getMonthName.bind(this);
    this.getPositiveAmount = this.getPositiveAmount.bind(this);
    this.findMostFrequentElement = this.findMostFrequentElement.bind(this);
    this.getMonthlyData = this.getMonthlyData.bind(this);
    this.findTopCategories = this.findTopCategories.bind(this);
    this.gatherClassificationData = this.gatherClassificationData.bind(this);
    this.buildClassificationPayload = this.buildClassificationPayload.bind(this);
  }

  componentDidMount() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountTransactions(bank_id, account_id, accountType);
    this.fetchAccessToken(bank_id);
  }

  componentWillUpdate(nextProps, nextState){
    if (this.props.match.params.account_id !== nextProps.match.params.account_id) {
      const bank_id = nextProps.match.params.bank_id;
      const account_id = nextProps.match.params.account_id;
      const accountType = nextProps.accountType;

      this.setState({
        transactions: [],
        classificationByMonth: [],
        transactionsFetchedAt: null,
        accessToken:""
      });

      this.fetchAccountTransactions(bank_id, account_id, accountType);
      this.fetchAccessToken(bank_id);
    }
  }

  fetchAccessToken(bank_id) {
    // truelayer access token
    this.setState({ showAccessTokenLoader: true });
    authFetch.get(`${getUsersApiUri()}/banks/${bank_id}/access_token`)
      .then((res)=>{
        const token = res.data.access_token;
        this.setState({ showAccessTokenLoader: false });
        this.setState({ accessToken:token });
      })
      .catch((err)=>{
        console.log(err);
        this.setState({ accessToken:"failed to fetch" });
      });
  }

  fetchAccountTransactions(bank_id, account_id, accountType, loadMore = false) {
    const { dispatch } = this.props;

    if(loadMore){
      this.setState({ showMoreTransactionsLoader: true })
    } else {
      this.setState({ showTransactionsLoader: true, transactionsError: false })
    }

    let url = "";
    if (accountType === "account") {
      url = `${getUsersApiUri()}/banks/${bank_id}/accounts/${account_id}/transactions`;
    } else {
      url = `${getUsersApiUri()}/banks/${bank_id}/cards/${account_id}/transactions`;
    }

    let queryParams;
    if (this.state.transactionsToDate && loadMore){
      queryParams = {params: {toDate: this.state.transactionsToDate}}
    } else {
      queryParams = {}
    }

    authFetch.get(url, queryParams)
      .then((response) => {
        if (loadMore) {
          this.setState({
            transactions: this.state.transactions.concat(response.data.transactions),
            transactionsToDate: response.data.toDate,
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: true,
            transactionsFetchedAt: new Date(),
            classificationByMonth: response.data.classification_by_month
          });
        } else {
          this.setState({
            transactions: response.data.transactions,
            transactionsToDate: response.data.toDate,
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: true,
            transactionsFetchedAt: new Date(),
            classificationByMonth: response.data.classification_by_month
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(autoAlert('Your session has expired!', 'warning', 'Ooops!'));
          dispatch(logoutUser());
          return;
        }
        this.setState({
          showTransactionsLoader: false,
          showMoreTransactionsLoader: false,
          transactionsFetched: false
        });
        console.log(err);
        if (loadMore){
          this.setState({
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: false
          });
        } else {
          this.setState({
            showTransactionsLoader: false,
            showMoreTransactionsLoader: false,
            transactionsFetched: false,
            transactionsError: true
          });
        }
      })
  }

  refetchAccountTransactions() {
    const bank_id = this.props.match.params.bank_id;
    const account_id = this.props.match.params.account_id;
    const accountType = this.props.accountType;
    this.fetchAccountTransactions(bank_id, account_id, accountType);
  }

  // Given "2020-12", return "Dec"
  getMonthShorthand(monthString) {
    let monthDate = new Date(monthString);
    return new Intl.DateTimeFormat("default", { month: "short"  }).format(monthDate);
  }

  // Given "2020-12", return "December"
  getMonthName(monthString) {
    let monthDate = new Date(monthString);
    return new Intl.DateTimeFormat("default", { month: "long"  }).format(monthDate);
  }

  getPositiveAmount(amount) {
    let amountAsNumber = parseFloat(amount);
    let positiveAmount = amountAsNumber < 0 ? Math.abs(amountAsNumber) : amountAsNumber;
    return positiveAmount.toFixed(2);
  }

  findMostFrequentElement(array) {
    let result, best = -1, lookup = {};

    for (let i = 0; i < array.length; i++) {
      if (lookup[array[i]] === undefined) {
        lookup[array[i]] = 0;
      }

      lookup[array[i]]++;

      if (lookup[array[i]] > best) {
        best = lookup[array[i]];
        result = array[i]
      }
    }

    return result;
  }

  // Ensures positive amounts and sorts category breakdowns by amount, in descending order
  getMonthlyData() {
    const rawMonthItems = this.getRawMonthItems().map((month) => {
      month.total = this.getPositiveAmount(month.total);
      month.breakdown_by_category = month.breakdown_by_category.map((category) => {
        category.amount = this.getPositiveAmount(category.amount);
        return category;
      }).filter(c => c.amount > 0)
        .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))

      return month;
    });

    return rawMonthItems;
  }

  findTopCategories(months) {
    let allTopCategories = [[], [], [], [], []];
    months.forEach((month) => {
      month.breakdown_by_category.slice(0, 5).forEach((element, index) => {
        allTopCategories[index].push(element.category)
      })
    });

    let topCategories = [];
    allTopCategories.forEach((categories) => {
      let eligibleCategories = categories.filter(el => !topCategories.includes(el));
      let topCategory = this.findMostFrequentElement(eligibleCategories);
      topCategories.push(topCategory);
    })

    return topCategories;
  }

  gatherClassificationData(topCategories, months) {
    let categoryArrays = topCategories.map((category, index) => {
      return months.map((monthItem) => {
        let foundCategory = monthItem.breakdown_by_category.find(el => el.category === category);
        return foundCategory ? foundCategory.amount : 0;
      });
    });

    categoryArrays.forEach((categoryArray, index) => {
      if (categoryArray.every((value, i, array) => value === 0 && value === array[0])) {
        categoryArrays.splice(index, 1);
        topCategories.splice(index, 1);
      }
    });

    return [topCategories, ...categoryArrays]
  }

  // Sorts months are in ascending order
  getRawMonthItems() {
    return this.state.classificationByMonth
      .sort((a,b) => (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0));
  }

  buildClassificationPayload() {
    let monthlyData = this.getMonthlyData();
    let monthItems = monthlyData.map((m) => ({
      ...m,
      breakdown_by_category: m.breakdown_by_category.filter(c => c.category !== "Uncategorized")
    }));
    let topCategories = this.findTopCategories(monthItems);
    let colorScheme = ["rgba(7, 11, 107, 1)", "rgba(48, 65, 194, 1)", "rgba(84, 99, 226, 1)",
      "rgba(118, 157, 252, 1)", "rgba(213, 237, 255, 1)"];
    let dataByCategory = this.gatherClassificationData(topCategories, monthItems);
    let finalCategories = dataByCategory.shift();

    return dataByCategory.map((category, index) => {
      return {
        label: finalCategories[index],
        data: dataByCategory[index],
        backgroundColor: colorScheme[index]
      }
    });
  }

  render() {
    const bankId = this.props.match.params.bank_id;
    const accountType = this.props.accountType;
    const accountId = this.props.match.params.account_id;
    const chartLabels = this.getRawMonthItems().map((i) => { return this.getMonthShorthand(i.month) });
    const chartDatasets = this.buildClassificationPayload();
    const monthlyData = this.getMonthlyData();
    const classificationMonthlyBreakdown = monthlyData.reverse();

    return (
      <div className="col-lg-9">
        <ReactTooltip effect="solid" />

        <AccountTabNav bankId={bankId} accountId={accountId} accountType={accountType} />

        {!this.state.showTransactionsLoader ? (<>
          {!this.state.transactionsError ? (<>
            {this.state.transactions.length > 0 ? (
              <div className="card mt-4 tl-chart">
                <BarChart
                  labels={chartLabels}
                  datasets={chartDatasets}
                  variant="classification"
                />
              </div>
            ) : (
              <div className="card mt-4 tl-chart">No transactions found</div>
            )}
          </>) : (
            <div className="card mt-4 tl-chart">
              <div className="error">
                Something went wrong.&nbsp;
                <a onClick={this.refetchAccountTransactions}>You may want to try again?</a>
              </div>
            </div>
          )}
        </>) : (
          <div className="card mt-4 tl-chart">
            <ContentPageLoader />
          </div>
        )}

        {!this.state.showTransactionsLoader ? (<>
          {!this.state.transactionsError ? (
            <div id="classifications">
              {classificationMonthlyBreakdown.length > 0 ? (
                classificationMonthlyBreakdown.map((monthItem) => {
                  let breakdown = monthItem.breakdown_by_category;
                  let uncategorisedIndex = breakdown.findIndex(el =>
                    el.category === "Uncategorized");

                  if (uncategorisedIndex >= 0) {
                    let uncategorisedElement = breakdown.splice(uncategorisedIndex, 1);
                    breakdown.push(uncategorisedElement[0]);
                  }

                  let breakdownRest = breakdown.splice(Math.ceil(breakdown.length / 2),
                    Math.floor(breakdown.length / 2));
                  let oddClass = breakdown.length === breakdownRest.length ? "" : "table-odd";
                  let splitBreakdown = [breakdown, breakdownRest];

                  return (
                    <div key={monthItem.month} id="classifications" className="card mt-4">
                      <div className="row">
                        <div className="col col-lg-6"><h5>{this.getMonthName(monthItem.month)}</h5></div>

                        {this.state.transactionsFetched && this.state.transactionsFetchedAt &&
                        <div className="col col-lg-6 text-right last-update">
                          <p>Updated <Moment fromNow>{this.state.transactionsFetchedAt}</Moment></p>
                        </div>}
                      </div>

                      <div className="row">
                        {splitBreakdown.map((breakdownItem, index) => {
                          let tableClass = `part-${index} ${index === 1 ? oddClass : ""}`;

                          return breakdownItem.length > 0 ? (
                            <div key={`classification-table-${index}`} className="col col-lg-6">
                              <table className={`table table-hover table-responsive ${tableClass}`}>
                                <thead>
                                  <tr>
                                    <th className="classification-icon" colSpan="3">Category</th>
                                    <th className="classification-amount">Amount</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {breakdownItem.map((categoryItem) => {
                                    const categoryName = categoryItem.category;
                                    const iconClass = categoryName.toLowerCase().replace(/[ &]+/g, '-');
                                    const percentage = categoryItem.percentage_of_total.toFixed() + "%";
                                    const progressStyle = { width: percentage };

                                    return (
                                      <tr key={`${monthItem.month}-${iconClass}`}>
                                        <td className="classification-icon transaction-icon">
                                          <span className={iconClass}></span>
                                        </td>
                                        <td className="classification-category">{categoryName}</td>
                                        <td className="classification-percentage">
                                          <span className="background">
                                            <span className="progress" style={progressStyle}></span>
                                            <span className="label">{percentage}</span>
                                          </span>
                                        </td>
                                        <td className="classification-amount">
                                          {formattedCurrencyAmount(monthItem.currency, categoryItem.amount)}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div key={`classification-table-${index}`} className="col col-lg-6"></div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="row">No transactions found</div>
              )}
            </div>
          ) : (
            <div id="classifications" className="card mt-4">
              <div className="row">
                <div className="col col-lg-6"><h5>Monthly breakdown</h5></div>
              </div>

              <div className="row">
                <div className="error">
                  Something went wrong.&nbsp;
                  <a onClick={this.refetchAccountTransactions}>You may want to try again?</a>
                </div>
              </div>
            </div>
          )}
        </>) : (
          <div id="classifications" className="card mt-4">
            <div className="row">
              <div className="col col-lg-6"><h5>Monthly breakdown</h5></div>
            </div>

            <ContentPageLoader />
          </div>
        )}
      </div>
    );
  }
}

const propTypes = {
  dispatch: PropTypes.func.isRequired
}
AccountClassification.propTypes = propTypes;

function mapStateToProps(state) {
  const { user, general, banks } = state;
  const { contentLoading } = general;
  const { isSignedIn, attributes } = user;
  const { items } = banks;

  return {
    contentLoading,
    isSignedIn,
    userAttributes: attributes,
    bankItems: items
  }
}

export default withRouter(connect(mapStateToProps)(AccountClassification));
